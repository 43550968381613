import { createSlice } from '@reduxjs/toolkit';

export const showLoadingSlice = createSlice({
  name: 'showLoading',
  initialState: {
    isShow: false,
  },
  reducers: {
    onLoading: (state) => {
      state.isShow = true;
    },
    offLoading: (state) => {
      state.isShow = false;
    },
  },
});

export const { onLoading, offLoading } = showLoadingSlice.actions;
export default showLoadingSlice.reducer;
