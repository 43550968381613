import { configureStore } from '@reduxjs/toolkit';
import showLoadingReducer from './showLoadingSlice';
import showModalReducer from './showModalSlice';
import modalMessageReducer from './modalMessageSlice';

export const store = configureStore({
  reducer: {
    showLoading: showLoadingReducer,
    showModal: showModalReducer,
    modalMessage: modalMessageReducer,
  },
});
