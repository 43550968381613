import { createSlice } from '@reduxjs/toolkit';

export const showModalSlice = createSlice({
  name: 'showModal',
  initialState: {
    isShow: false,
    message: '',
    isSendSuccess: false,
  },
  reducers: {
    onModal: (state) => {
      state.isShow = true;
    },
    offModal: (state) => {
      state.isShow = false;
    },
    updateMessage: (state, action) => {
      state.message = action.payload;
    },
    setIsSendSuccess: (state, action) => {
      state.isSendSuccess = action.payload;
    },
  },
});

export const { onModal, offModal, updateMessage, setIsSendSuccess } = showModalSlice.actions;
export default showModalSlice.reducer;
