import React from 'react';
import { useInView } from 'react-intersection-observer';

type Props = {
  children: React.ReactElement;
};

const ScrollAnimation: React.FC<Props> = ({ children }) => {
  const [ref, inView] = useInView({
    rootMargin: '0px',
    triggerOnce: true,
    threshold: .5,
  });

  const animate = inView ? 'animate' : '';

  return (
    <div ref={ref} className={`scroll-animation ${animate}`}>
      { children }
    </div>
  )
};

export default ScrollAnimation;
