import parse from 'html-react-parser';

type Props = {
  title: string,
  text: string,
  image: string,
  isHtmlParse?: boolean,
}

const DetailPageContent = ({ title, text, image, isHtmlParse = false }: Props): JSX.Element => {
  return (
    <div className="detail_page_content">
      <div className="title">
        <h1>{ title }</h1>
      </div>
      <div className="conteiner">
        <div className="image">
          <img src={ image } alt="" />
        </div>
        <div className="text">
          {
            isHtmlParse
              ? <div className="parse">{ parse(text) }</div>
              : <p>{ text }</p>
          }
        </div>
      </div>
    </div>
  );
}

export default DetailPageContent;
