import { createSlice } from '@reduxjs/toolkit';

export const modalMessage = createSlice({
  name: 'modalMessage',
  initialState: {
    message: '',
  },
  reducers: {
    updateMessage: (state, action) => {
      state.message = action.payload;
    },
  },
});

export const { updateMessage } = modalMessage.actions;
export default modalMessage.reducer;
