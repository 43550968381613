import DetailPageContent from '../components/templates/DetailPageContent';
import { DETAIL_PAGE_TEXTS, DETAIL_PAGE_IMAGES } from '../const';
import BreadcrumbList from '../components/organisms/BreadcrumbList';
import { LINKS } from '../const';
import LinkButton from '../components/atoms/LinkButton';

const Service = (): JSX.Element => {
  return (
    <>
      <BreadcrumbList currentPage={ LINKS[3].text } />
      <DetailPageContent
        title="サービス"
        text={ DETAIL_PAGE_TEXTS.service }
        image={ DETAIL_PAGE_IMAGES.service }
        isHtmlParse={ true }
      />
      <LinkButton href='/' caption='ホームへ'/>
      <br/>
      <br/>
    </>
  );
};

export default Service;
