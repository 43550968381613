type Props = {
  mainMassage: string,
  image: string,
}

const MainMessage = ({ mainMassage, image }: Props): JSX.Element => {

  return (
    <>
      <div className='main_message'>
        <p style={{ whiteSpace: 'pre-line' }}>{mainMassage}</p>
      </div>
    </>
  );
}

export default MainMessage;
