import { useSelector } from 'react-redux';
import { MAIN_MAIL_ADRESS } from '../../const';

interface Props {
  handleCloseModal: () => void;
}

type showModal = {
  showModal: {
    message: string;
    isSendSuccess: string;
  };
}

const Modal: React.FC<Props> = ({ handleCloseModal }) => {
  const message = useSelector((state: showModal) => state.showModal.message);
  const isSendSuccess = useSelector((state: showModal) => state.showModal.isSendSuccess);

  return (
    <div className="modal-window">
      <div className="content">
        <p style={{ whiteSpace: 'pre-line' }}>{ message }</p>
        { isSendSuccess || <p className="mail-address">{ MAIN_MAIL_ADRESS }</p> }
        <button onClick={ handleCloseModal }>閉じる</button>
      </div>
    </div>
  );
};

export default Modal;
