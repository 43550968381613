export const BREAKPOINT_SP: number = 480;
export const BREAKPOINT_TAB: number = 768;
export const BREAKPOINT_PC: number = 1080;

export const ICON_IMAGE: string = `/images/icon.png`;
export const HEADER_TITLE_IMAGE: string = `/images/header_title.png`;
export const FOOTER_TITLE: string = '©橘柚羽公式サイト';
export const MAIN_MAIL_ADRESS = 'tachibana.yu31@gmail.com';

type ObjectForString = {
  [name: string]:  string
}

export const SWIPER_IMAGES: ObjectForString[] = [
  {src: '/images/top1.png', alt: 'スライド画像1'},
  {src: '/images/top2.png', alt: 'スライド画像2'},
  {src: '/images/top3.png', alt: 'スライド画像3'},
]

export const LINKS: ObjectForString[] = [
  {link: '/', text: 'ホーム'},
  {link: 'profile', text: 'プロフィール'},
  {link: 'about', text: '断易とは'},
  {link: 'service', text: 'サービス'},
]

export const MAIN_MESSAGE: string = `今を変えたい、今は進むべきかやめるべきか、右と左のどちらがいいか、人生には転機が幾度もあり思い悩むことがあります。
あの時、これでよかったのかな･･･もしかしたら別の道を選択していたら･･･と、前を向いて歩けない時があります。そんな時に少しでも元気になり前を向いて一歩踏み出す勇気の手助けができたらと思っております。

中国の秘宝「断易」と九星気学を組み合わせ高確率で当たる占いを提供しております。
吉・凶やどうすれば良くなるか、いつごろどうしたら良いかまで鑑定いたします。
恋愛、仕事、人間関係、家族、健康のことなどなんでもご相談ください。
`;

export const CONTENT_SUB_TITLES: ObjectForString = {
  profile: 'プロフィール',
  about: '断易とは',
  service: 'サービス',
  contact: 'お問い合わせ',
};

export const CONTENT_TEXTS: ObjectForString = {
  profile: `10年前に「断易」に出会い、縁があり日本でも最も難易度の高い「諸口流断易」を学びました。
断易と九星気学を通して、仕事や恋愛、運勢、問題の解決方法についてお伝えします。
  `,
  about: `易経の一つで約2300年前中国の戦国時代に楚の鬼谷子によって創定をされたと言われています。

易卦の六十四卦に十二支を割り振り、陰陽五行とよばれる大自然の法則を組み合わせ、その力の強弱や生尅関係などによって判断をする占いのため「五行易」とも呼ばれています。

サイコロやコインなどを用いて占い、占いの目的に対して吉・凶がはっきり出る上にその結果の出る時期や対処法などがわかる占術です。
  `,
  service: `
    <div>
      <b><u>オンライン鑑定</u></b>
      <ul style="list-style: none; padding-left: 1em;">
        <li>10分  1,000円</li>
        <li>30分  3,000円</li>
        <li>追加料金  10分  1,000円</li>
      </ul>
      <b><u>メール鑑定</u></b>
      <ul style="list-style: none; padding-left: 1em;">
        <li>メール1通  3,000円</li>
      </ul>
      <p>オンライン鑑定の日時、メール鑑定のお申込みにつきましてはメールにてお問い合わせください。</p>
    </div>
  `
}

export const CONTENT_IMAGES: ObjectForString = {
  profile: '/images/profile.png',
  about: '/images/about.png',
  service: '/images/service.png'
}

export const DETAIL_PAGE_TEXTS: ObjectForString = {
  profile: `ご覧いただきありがとうございます。
橘　柚羽（たちばな　ゆう）と申します。

会社員の父と専業主婦の母のもとに生まれ育ち、結婚、出産と家族も増え3人の子育てに追われる日々を過ごしておりましたが夫は転職を繰り返し無職の時期もあり収入が安定せず、私が働き家計を支えていました。
借金が増え、家庭も荒れ、このままでは3人の子供を育てられないと思い離婚を決意し一人で3人の子供を育てました。

離婚してからも生活は苦しく、子供がけがをし、けんかも絶えず、思い悩んでいたところ断易に出会いました。
現状を変え、進むべき道を導く断易に共感し、縁がありJOJO広重先生に師事し諸口流断易を学びました。

今までの経験は決して無駄ではなく、あの時の苦労があったからこそ断易の鑑定士になることができたと思います。

人は悩み、迷い、進むべき道を見失うときがあります。
鑑定士として、寄り添い、一緒に向き合い、少しでも元気になり、前を向いて一歩踏み出す勇気の手助けができればと思っております。
  `,
  about: `「易」と名前がつく占いで「周易（しゅうえき）」は有名ですが「断易（だんえき）」という占いはあまりなじみがないかもしれません。
周易は筮竹（ぜいちく）を束ねて占う方法で日本では古来より周易が広まりました。
しかし易の本家本元の中国では大抵の易者は周易ではなく、断易で占っていると言われています。それは断易の方がよく当たるからだそうです。

周易は運命学として最高のものですが、実際に占う場合は断易の方が吉凶に関して判断ができ、また細かな点まで詳しくわかり、そしてよく的中すると言われています。

断易は古代中国の占術の一つであり、春秋戦国時代に楚の鬼谷子によって想定をされたと言われています。兵法として敵陣を攻め落とすにはどう攻撃をしたら勝てるか、攻めるのはいつか、勝つか負けるか、どうすれば勝てるかなど、今前に進むべき道を見ることができる占いです。

断易は易卦の六十四卦に十二支を割り振り、陰陽五行とよばれる大自然の法則を組み合わせ、その力の強弱や位置、意味、生尅関係などを読み取っていく占いのため、「五行易」とも呼ばれています。

断易占いの的中率は高いと言われていますが、日本であまり広まらないのは専門書が少なく、また本を読んだとしてもそれだけでは理論は理解できても実際に占断ができないからと言われています。専門的な知識と経験を必要とするため、正確な結果を得るには十分な学習と訓練、また優秀かつ実際に占いをしている先生に口伝で習う必要があります。

日本の断易界で正統派と言われている諸口流断易を習得し、これからはたくさんの人へ幸せになるための道しるべをお伝えできればと思っております。
  `,
  service: `<div>
      <b><u>オンライン鑑定</u></b>
      <ul style="list-style: none; padding-left: 1em;">
        <li>10分  1,000円</li>
        <li>30分  3,000円</li>
        <li>追加料金  10分  1,000円</li>
      </ul>
      <ul>
        <li>メールにてご希望の日時をお問い合わせください。<br>
            <strong>${ MAIN_MAIL_ADRESS }</strong>
        </li>
        <li>お客様のアドレスに日程の調整などについてご連絡いたします。</li>
        <li>
          ご予約が確定しましたら、オンライン鑑定の受付はこちらからお願いいたします。<br>
          ■STORES: <a href="https://tachibana-yu.stores.jp">https://tachibana-yu.stores.jp</a>
        </li>
        <li>
          内容確認次第、受付受諾の連絡と合わせて鑑定URLをご連絡いたします。
          ※@gmail.comからのメールの受信ができるように設定をお願いいたします。
        </li>
      </ul>
      <p style="white-space: normal; margin: 20px 0 0">
        <b>【鑑定に必要な情報】</b><br>
        こちらの情報はオンライン鑑定の前までにご連絡ください。
      </p>
      <ul>
        <li>お名前</li>
        <li>生年月日（西暦）</li>
        <li>ご相談内容</li>
      </ul>
      <p style="white-space: normal;">
        事前にご連絡がなくキャンセルの場合は、返金対応はできませんのでお早めに日程変更のご連絡をお願いいたします。
        <br>
        <br>
        鑑定内容はあくまでも行動の指針となるものです。将来起こる出来事を保証するものではありません。<br>
        ご了承の上、お申し込みください。
      </p>
      <b><u>メール鑑定</u></b>
      <ul style="list-style: none; padding-left: 1em;">
        <li>メール1通  3,000円</li>
      </ul>
      <ul style="list-style: none; text-indent: -1em; padding-left: 2em;">
        <li>
          (１)メール鑑定のお申込み<br>
          メールの件名に【メール鑑定申し込み】と明記し、「鑑定に必要な情報」をご記載の上<strong>${ MAIN_MAIL_ADRESS }</strong>までお送りください。<br>
          <br>
          <b style="padding-top: 20px">【鑑定に必要な情報】</b>
          <ul style="list-style: disc;">
            <li style="text-indent: -0.5em; padding-left: 0.5em;">お名前</li>
            <li style="text-indent: -0.5em; padding-left: 0.5em;">生年月日（西暦）</li>
            <li style="text-indent: -0.5em; padding-left: 0.5em;">メールアドレス</li>
            <li style="text-indent: -0.5em; padding-left: 0.5em;">
              住所<br>
              ※以下はお分かりになればご記入ください。<br>
              ①現在の住所に住み始めた時期<br>
              ②現在の住所の前に住んでいた住所
            </li>
            <li style="text-indent: -0.5em; padding-left: 0.5em;">
              ご相談内容<br>
              ご相談内容は出来るだけ詳細に書いていただけると詳しく鑑定できます。<br>
              お相手がいらっしゃる場合はお相手の方の生年月日も分かれば記載をお願いいたします。<br>
              追加でご相談をなさる場合は、再度メール鑑定をお申し込みしていただくことになり料金が発生いたします。
            </li>
          </ul>
        </li>
        <li style="padding-top: 20px">
          (２)メール鑑定の受付はこちらから<br>
          ■STORES: <a href="https://tachibana-yu.stores.jp">https://tachibana-yu.stores.jp</a>
        </li>
        <li style="padding-top: 20px">
          (３)鑑定結果メールの返信<br>
          STORESにてメール鑑定受付確認後、72時間以内に鑑定結果メールをお送りいたします。<br>
          天災など特別な事情で72時間以内に返信できない場合は必ず別途メールでご連絡いたします。<br>
          当方からの鑑定結果メールは1回のみとなります。<br>
          内容にご不明な点などがございましたらお気軽にお問い合わせください。<br>
          内容にかかわらず、一旦お支払いいただいた鑑定料を返金することはございません。<br><br>
          鑑定内容はあくまでも行動の指針となるものです。将来起こる出来事を保証するものではありません。<br>
          ご了承の上、お申し込みください。
        </li>
      </ul>
    </div>
  `,
}

export const DETAIL_PAGE_IMAGES: ObjectForString = {
  profile: '/images/profile_detail.png',
  about: '/images/about_detail.png',
  service: '/images/service_detail.png',
}

export const MAIL_OPTIONS: ObjectForString = {
  from: 'string',
  to: 'string',
  subject: 'string',
  text: 'string',
}

export const ERROR_MESSAGE: ObjectForString = {
  requireError: '※すべての項目を入力してください。',
  emailError: '※メールアドレスが無効な形式です。',
  notSameError: '※メールアドレスが確認用アドレスと同一でありません。',
}

export const MODAL_MESSAGE: ObjectForString = {
  sendSuccess: 'お問い合わせを受付けました。',
  sendError: `お問い合わせの受付に失敗しました。
    お手数をおかけしますが、以下のメールアドレスに直接お問い合わせ頂けると幸いです。
  `
}

export const LOG_MESSAGE: ObjectForString = {
  validationError: 'バリエーションエラーが発生しました。',
  requestSucsess: 'リクエストに成功しました。',
  requestFailed: 'リクエストに失敗しました。',
  requestError: 'エラーが発生しました。',
}
