import { useState, ChangeEvent } from 'react';
import { Form, Button } from 'react-bootstrap';
import axios from 'axios';
import { useDispatch } from 'react-redux';
import { onLoading, offLoading } from '../../redux/showLoadingSlice';
import { onModal, updateMessage, setIsSendSuccess } from '../../redux/showModalSlice';
import { ERROR_MESSAGE, MODAL_MESSAGE, LOG_MESSAGE } from '../../const';
import { isSsl } from '../../utility';

type Props = {
  subTitle: string,
}

const Contact = ({ subTitle }: Props): JSX.Element => {
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    emailConfirm: '',
    message: ''
  });
  const [errorMessage, setErrorMessage] = useState('');
  const [requireErrors, setRequireErrors] = useState({
    name: false,
    email: false,
    emailConfirm: false,
    message: false
  });
  const [emailError, setEmailError] = useState(false);
  const [notSameError, setNotSameError] = useState(false);

  const dispatch = useDispatch();

  //お問い合わせフォームのデータをセット
  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    const { id, value } = e.target;

    setFormData((prevData) => ({
      ...prevData,
      [id]: value
    }));
  };

  //モーダルの表示非表示を操作
  const handleModal = (message: string): void => {
    dispatch(updateMessage(message));
    dispatch(offLoading());
    dispatch(onModal());
  }

  //お問い合わせフォームに入力している値をリセット
  const resetFormData = (): void => {
    setFormData((prev) => ({
      ...prev,
      name: '',
      email: '',
      emailConfirm: '',
      message: ''
    }));

    resetValidationError();
  }

  //バリデーションエラー関連のデータをリセット
  const resetValidationError = (): void => {
    setRequireErrors((prev) => ({
      ...prev,
      name: false,
      email: false,
      emailConfirm: false,
      message: false
    }));
    setEmailError(false);
    setNotSameError(false);

    setErrorMessage('');
  }

  //メール送信に関する処理
  const sendMail = async(event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    dispatch(onLoading());

    let url: string = '';
    if (isSsl()) {
      url = process.env.REACT_APP_SEND_MAIL_API_SSL !== undefined
        ? process.env.REACT_APP_SEND_MAIL_API_SSL
        : '';
    } else {
      url = process.env.REACT_APP_SEND_MAIL_API !== undefined
        ? process.env.REACT_APP_SEND_MAIL_API
        : '';
    }

    try {
      const response = await axios.post(url, formData);
      console.log(response);

      if (response.status === 200) {
        //リクエスト成功時の処理
        const isRequireError = response.data.isRequireError;
        const requireErrors = response.data.requireErrors;
        const isEmailError = response.data.isEmailError;
        const isNotSameError = response.data.isNotSameError;

        //必須チェックのバリエーションエラー発生時の処理
        if (isRequireError) {
          resetValidationError();
          setErrorMessage(ERROR_MESSAGE.requireError)
          setRequireErrors(preErrors => ({
            ...preErrors,
            ...requireErrors,
          }));

          console.log(LOG_MESSAGE.validationError, response);
          dispatch(offLoading());

          return;
        }

        //メールアドレスチェックのバリエーションエラー発生時の処理
        if (isEmailError) {
          resetValidationError();
          setErrorMessage(ERROR_MESSAGE.emailError);
          setEmailError(true);

          console.log(LOG_MESSAGE.validationError, response);
          dispatch(offLoading());

          return;
        }

        //メールアドレスが確認用アドレスと異なるエラーが発生した時の処理
        if (isNotSameError) {
          resetValidationError();
          setErrorMessage(ERROR_MESSAGE.notSameError);
          setNotSameError(true);

          console.log(LOG_MESSAGE.validationError, response);
          dispatch(offLoading());

          return;
        }

        const isSendSuccess = response.data.isSendSuccess;
        const modalMessage: string = isSendSuccess
          ? MODAL_MESSAGE.sendSuccess
          : MODAL_MESSAGE.sendError;

        console.log(LOG_MESSAGE.requestSucsess, response);
        dispatch(setIsSendSuccess(isSendSuccess));
        handleModal(modalMessage);

        if(isSendSuccess) {
          resetFormData();
        } else {
          resetValidationError();
        }
      } else {
        //リクエスト失敗時の処理
        console.error(LOG_MESSAGE.requestFailed, response);
        dispatch(setIsSendSuccess(false));
        handleModal(MODAL_MESSAGE.sendError);
      }
    } catch (error) {
      //エラー発生時の処理
      console.error(LOG_MESSAGE.requestError, error);
      dispatch(setIsSendSuccess(false));
      handleModal(MODAL_MESSAGE.sendError);
    }
  }

  return (
    <>
      <div className='contact'>
        <h2>{ subTitle }</h2>
        <div className='contact_content'>
          {errorMessage && <p className="error_message">{errorMessage}</p>}
          <Form onSubmit={ sendMail }>
            <Form.Group className="mb-3" controlId="name">
              <Form.Label>お名前</Form.Label>
              <Form.Control
                className={requireErrors.name ? 'validation_error' : ''}
                type="text"
                value={formData.name} onChange={ handleChange }
              />
            </Form.Group>
            <Form.Group className="mb-3" controlId="email">
              <Form.Label>メールアドレス</Form.Label>
              <Form.Control
                className={ requireErrors.email || emailError ? 'validation_error' : '' }
                type="email" value={ formData.email }
                onChange={ handleChange }
              />
            </Form.Group>
            <Form.Group className="mb-3" controlId="emailConfirm">
              <Form.Label>メールアドレス確認用</Form.Label>
              <Form.Control
                className={ requireErrors.emailConfirm || notSameError ? 'validation_error' : '' }
                type="email" value={ formData.emailConfirm }
                onChange={ handleChange }
              />
            </Form.Group>
            <Form.Group className="mb-3" controlId="message">
              <Form.Label>メッセージ</Form.Label>
              <Form.Control
                className={ requireErrors.message ? 'validation_error' : '' }
                as="textarea"
                rows={ 5 }
                value={ formData.message }
                onChange={ handleChange }
              />
            </Form.Group>

            <div className="detail_button">
              <Button variant="primary" type="submit">送信</Button>
            </div>
          </Form>
        </div>
      </div>
    </>
  );
}

export default Contact;
