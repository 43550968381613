import { Swiper, SwiperSlide } from 'swiper/react';
import SwiperCore, { Pagination, Autoplay, EffectFade } from "swiper";
import 'swiper/css';
import 'swiper/css/effect-fade';
import {
  SWIPER_IMAGES
} from '../../const';
import { generateUniqueKey } from '../../utility';

SwiperCore.use([Pagination, Autoplay]);

const Top = (): JSX.Element => {

  return (
    <div className='top_conteiner'>
      <div className='top_content'>
        <Swiper
          spaceBetween={.1}
          slidesPerView={1}
          autoplay={true}
          loop={true}
          speed={900}
          modules={[EffectFade]}
          effect="fade"
          simulateTouch={ false }
          slideToClickedSlide={ false }
        >
          { SWIPER_IMAGES.map((SWIPER_IMAGE) => (
            <SwiperSlide key={ generateUniqueKey() }>
              <div className='top'>
                <img
                  className="main_image"
                  src={ SWIPER_IMAGE.src }
                  alt={ SWIPER_IMAGE.alt }
                />
              </div>
            </SwiperSlide>
          )) }
        </Swiper>
      </div>
    </div>
  );
}

export default Top;
