import Breadcrumb from 'react-bootstrap/Breadcrumb';

type Props = {
  currentPage: string,
}

function BreadcrumbList( { currentPage }: Props) {
  return (
    <div className="bread_crumb_list">
      <Breadcrumb>
        <Breadcrumb.Item href="/">ホーム</Breadcrumb.Item>
        <Breadcrumb.Item active>{ currentPage }</Breadcrumb.Item>
      </Breadcrumb>
    </div>
  );
}

export default BreadcrumbList;
