import { BrowserRouter, Routes, Route } from 'react-router-dom';
import Home from './ts/pages/Home';
import Profile from './ts/pages/Profile';
import About from './ts/pages/About';
import Service from './ts/pages/Service';
import Header from './ts/components/organisms/Header';
import Footer from './ts/components/organisms/Footer';
import 'bootstrap/dist/css/bootstrap.min.css';
import './App.css';
import { offModal } from './ts/redux/showModalSlice';
import { useSelector, useDispatch } from 'react-redux';
import Loading from './ts/components/organisms/Loading';
import Modal from './ts/components/organisms/Modal';

type showLoading = {
  showLoading: {
    isShow: boolean;
  };
}

type showModal = {
  showModal: {
    isShow: boolean;
  };
}

function App() {
  const dispatch = useDispatch();
  const showLoading = useSelector((state: showLoading) => state.showLoading.isShow);
  const showModal = useSelector((state: showModal) => state.showModal.isShow);

  const handleCloseModal = () => {
    dispatch(offModal())
  };

  return (
    <div className="App">
      <header>
        <Header />
      </header>
      <main>
        <BrowserRouter>
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/profile" element={<Profile />} />
            <Route path="/about" element={<About />} />
            <Route path="/service" element={<Service />} />
          </Routes>
        </BrowserRouter>
      </main>
      <footer>
        <Footer />
      </footer>
      {showLoading && <Loading />}
      {showModal && <Modal handleCloseModal={ handleCloseModal }/>}
    </div>
  );
}

export default App;
