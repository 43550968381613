import parse from 'html-react-parser';
import LinkButton from '../atoms/LinkButton';

type Props = {
  subTitle: string,
  text: string,
  image: string,
  imageDirection: string,
  first?: boolean,
  isHtmlPase?: boolean,
  href?: string,
}

type ImageProps = {
  image: string,
  imageDirection: string,
}

const Image = ({ image, imageDirection }: ImageProps): JSX.Element => {

  return (
    <>
      <div className='image'>
        <img className={imageDirection} src={ image } alt="" />
      </div>
    </>
  );
}

const HomeContent = ({
  subTitle,
  text,
  image,
  imageDirection,
  first = false,
  isHtmlPase = false,
  href = '',
}: Props): JSX.Element => {

  return (
    <>
      <div className={`
        home_content
        ${imageDirection}
        ${first && 'first'}
      `}>
        { imageDirection === 'left' &&
          <Image image={ image } imageDirection={ imageDirection }/> }

        <div className="content">
          <h2>{ subTitle }</h2>
          <div>
            {
              isHtmlPase
                ? <div>{ parse(text) }</div>
                : <p style={{ whiteSpace: 'pre-line' }}>{ text }</p>
            }
          </div>
        </div>

        { imageDirection === 'right' &&
          <Image image={ image } imageDirection={imageDirection}/> }
      </div>

      <LinkButton href={ href } caption='詳細'/>
    </>
  );
}

export default HomeContent;
