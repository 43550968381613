import DetailPageContent from '../components/templates/DetailPageContent';
import { DETAIL_PAGE_TEXTS, DETAIL_PAGE_IMAGES } from '../const';
import BreadcrumbList from '../components/organisms/BreadcrumbList';
import { LINKS } from '../const';
import LinkButton from '../components/atoms/LinkButton';

const Profile = (): JSX.Element => {
  return (
    <>
      <BreadcrumbList currentPage={ LINKS[1].text } />
      <DetailPageContent
        title="プロフィール"
        text={DETAIL_PAGE_TEXTS.profile}
        image={DETAIL_PAGE_IMAGES.profile}
      />
      <LinkButton href='/' caption='ホームへ'/>
      <br/>
      <br/>
    </>
  );
};

export default Profile;
