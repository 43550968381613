import "../../styles/Home.scss";
import Top from '../components/organisms/Top';
import MainMessage from '../components/organisms/MainMessage';
import HomeContent from '../components/organisms/HomeContent';
import Contact from '../components/organisms/Contact';
import ScrollAnimation from '../components/animation/ScrollAnimation';
import {
  MAIN_MESSAGE,
  CONTENT_SUB_TITLES,
  ICON_IMAGE,
  CONTENT_TEXTS,
  CONTENT_IMAGES,
  LINKS,
} from '../const';

const Home: React.FC = () => {

  return (
    <div className='home'>
      <Top />
      <div className='home_conteiner'>
        <ScrollAnimation>
          <MainMessage
            mainMassage={ MAIN_MESSAGE }
            image={ ICON_IMAGE }
          />
        </ScrollAnimation>

        <ScrollAnimation>
          <HomeContent
            subTitle={ CONTENT_SUB_TITLES.profile }
            text={ CONTENT_TEXTS.profile }
            image={ CONTENT_IMAGES.profile }
            imageDirection='right'
            first={ true }
            href={LINKS[1].link}
          />
        </ScrollAnimation>

        <ScrollAnimation>
          <HomeContent
            subTitle={ CONTENT_SUB_TITLES.about }
            text={ CONTENT_TEXTS.about }
            image={ CONTENT_IMAGES.about }
            imageDirection='left'
            href={ LINKS[2].link }
          />
        </ScrollAnimation>

        <ScrollAnimation>
          <HomeContent
            subTitle={ CONTENT_SUB_TITLES.service }
            text={ CONTENT_TEXTS.service }
            image={ CONTENT_IMAGES.service }
            imageDirection='right'
            isHtmlPase={ true }
            href={ LINKS[3].link }
          />
        </ScrollAnimation>

        <br/>
        <br/>
        <ScrollAnimation>
            <Contact
              subTitle={ CONTENT_SUB_TITLES.contact }
            />
        </ScrollAnimation>
      </div>
    </div>
  );
}

export default Home;
